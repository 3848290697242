import initScrollReveal from "./scripts/scrollReveal";
import initTiltEffect from "./scripts/tiltAnimation";
import { targetElements, defaultProps } from "./data/scrollRevealConfig";

initScrollReveal(targetElements, defaultProps);
initTiltEffect();

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAEqQ9x-tVRnnqjzuYydxuJPTNNSFKeRIs",
    authDomain: "calista-s-portfolio.firebaseapp.com",
    projectId: "calista-s-portfolio",
    storageBucket: "calista-s-portfolio.appspot.com",
    messagingSenderId: "634166937443",
    appId: "1:634166937443:web:548ba48d720422a1803556",
    measurementId: "G-X45QM627DT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);